<template>
  <div>
  <v-container id="dashboard" fluid tag="section">

    <base-material-card icon="mdi-calendar-heart">
      <slot slot="title">Event</slot>
      <slot slot="centerButtons" v-if="event.id">
        <div v-if="event.published">
          <v-btn color="error" @click="publish(false)">Un-publish</v-btn>
          <tooltip top>Your event is live and people can buy tickets.  Click UNPUBLISH to take your event offline.</tooltip>
        </div>
        <div v-else>
          <div v-if="ticketTypes && ticketTypes.length > 0 && eventTimes && eventTimes.length > 0">
            <v-btn color="error" @click="publish(true)">Publish</v-btn>
            <tooltip top>When you are ready to start selling tickets for this event, click PUBLISH.</tooltip>
          </div>
          <div v-else>
            <v-btn disabled color="error" @click="publish(true)">Publish</v-btn>
            <tooltip top>Please add at least one ticket type and one event time before publishing your event.</tooltip>
          </div>
        </div>

      </slot>
      <slot slot="rightButtons" v-if="event.id">
        <toggle-button text="Edit" openIcon="mdi-pencil" @toggle="showEdit" :showing="showEditToggle"/>
      </slot>
      <div v-if="showEditToggle">

        <v-form ref="event">
        <text-field label="Name" v-model="editEvent.name" :rules="[utils.requiredRule]">
          <slot slot="tooltip">This should be the name your event.<br/>
                You can change the event name at anytime.</slot> 
        </text-field>

        <text-field label="Email for correspondance" v-model="editEvent.email" :rules="[utils.emailRule]">
          <slot slot="tooltip">Your email for general correspondance with your customers.  <br/>This will appear at the bottom of their ticket orders.
          </slot> 
        </text-field>

        <text-field label="Location" v-model="editEvent.location">
          <slot slot="tooltip">The location of your event.  This could be an address or postcode so that people can find it easily.
          </slot> 
        </text-field>

        <text-field label="Path" v-model="editEvent.path" :rules="[utils.requiredRuleNoSpaces]">
          <slot slot="tooltip">This will form part of the link (url) that your customers will use to buy tickets.<br/>
                You can use any value and change it later.
          </slot> 
        </text-field>

        <text-field label="Ticket Pin" v-model="editEvent.pin" :rules="[utils.pinRule]">
          <slot slot="tooltip">When listing this event, for example on your Box Office page,<br/>
          events are shown in Priority order,<br/>Priority "1" indicates that the event will be shown first.</slot> 
        </text-field>

        <text-field label="URL when sold out (optional)" v-model="editEvent.soldoutUrl" :rules="urlValidRules">
          <slot slot="tooltip">You can supply an alternative url for when your tickets are sold out.<br/>
          When your fan clicks on a sold out ticket's button, they will be redirected to this url instead.</slot>
        </text-field>

        <div class="d-flex"><v-switch v-model="editEvent.allowDonations" :rules="[donationRule]"></v-switch><div class="pt-5" style="valign:middle">Allow Donations? 
          <tooltip left>Allowing donations means that customers can make an <br/>
        optional additional payment when they buy tickets.  <br/>This is especially useful for "Free" events.</tooltip></div>
        </div>

        <text-field v-if="editEvent.allowDonations" label="Donation Prompt" v-model="editEvent.donationPrompt">
          <slot slot="tooltip">This text will be show to customers to encourage them to donate.
          </slot> 
        </text-field>

        <text-field v-if="editEvent.allowDonations" label="Default Donation Amount" :prefix="getCurrencyPrefix(boxOffice.currency)" v-model="editEvent.defaultDonationAmount" :rules="[priceRule]">
          <slot slot="tooltip">Help people decide how much to donate.
          </slot> 
        </text-field>

        <div class="d-flex"><v-switch v-model="editEvent.hideDatesOnBoxOfficePage"></v-switch><div class="pt-5" style="valign:middle">Hide dates on Box Office page? 
          <tooltip left>If you don't want to show the date(s) for this event on the box office page,<br/> click here to hide them.<br/>
          You can use the Summary field to provide alternative text.</tooltip></div>
        </div>

        <div class="d-flex"><v-switch v-model="editEvent.collectLocation"></v-switch><div class="pt-5" style="valign:middle">Collect Location? 
          <tooltip left>Would you would like to collect location information from ticket buyers?</tooltip></div>
        </div>

        <div class="d-flex"><div class="pt-5 mr-5" style="valign:middle">Collect phone number when customers order tickets?</div>
          <v-select v-model="editEvent.collectPhoneNumber" :items='["no", "optional", "mandatory"]'/>
          <tooltip left>If you want to collect customer's phone numbers as they order, select "mandatory",<br>or 
            select "optional" to allow people to enter their phone number if they like.</tooltip>
        </div>

        <div class="d-flex"><v-switch v-model="editEvent.collectDateOfBirth"></v-switch><div class="pt-5" style="valign:middle">Collect date for birth from ticket buyers? 
          <tooltip left>If you would like to know your customer's date of birth (useful for age restricted events),<br/>
           click here to ask them for their birth date.</tooltip></div>
        </div>

        <text-field label="Summary" v-model="editEvent.summary">
          <slot slot="tooltip">A short summary of the event.  This will appear on your Box Office page
          </slot> 
        </text-field>

        <text-field label="Facebook Pixel" v-model="editEvent.facebookPixel">
          <slot slot="tooltip">If you would like to track hits for your facebook campaign,<br/>
           please enter the Facebook Pixel id here.</slot> 
        </text-field>

        <text-field label="Priority" v-model="editEvent.priority" :rules="[utils.integerRule]">
          <slot slot="tooltip">When listing this event, for example on your Box Office page,<br/>
          events are shown in Priority order,<br/>Priority "1" indicates that the event will be shown first.</slot> 
        </text-field>

        <div class="mt-4">
          <v-label>Colors
            <tooltip right>
              Choose a background and text color for your Event home page
            </tooltip>
          </v-label>
        </div>
        <select-colours :backgroundColour="event.backgroundColour" :textColour="event.textColour" @selectColours="selectColours"/> 

        <v-label>
          <div class="mt-3 mb-1">Event Image
            <tooltip top>
              Customise your Event home page by uploading an image to highlight your event.
            </tooltip>
          </div>
        </v-label>
        <img v-if="eventImage" :src="getCloudinaryImage({url: eventImage, width: 110})">
        <select-image-button :image="eventImage" :boxOffice="boxOffice" @selectImage="selectEventImage" @deleteImage="deleteEventImage"/>

        <v-label>
          <div class="mt-3 mb-1">Thumbnail Image
            <tooltip top>
              The thumbnail image will be shown for this event on your Box Office home page.
            </tooltip>
          </div>
        </v-label>
        <img v-if="thumbnail" :src="getCloudinaryImage({url: thumbnail, width: 110})">
        <select-image-button :image="thumbnail" :boxOffice="boxOffice" @selectImage="selectThumbnailImage" @deleteImage="deleteThumbnailImage"/>

        <v-label>
          <div class="mt-3 mb-1">Background Image
            <tooltip top>
              Optionally, add a background image for your event page.
            </tooltip>
          </div>
        </v-label>
        <img v-if="backgroundImage" :src="getCloudinaryImage({url: backgroundImage, width: 110})">
        <select-image-button :image="backgroundImage" :boxOffice="boxOffice" @selectImage="selectBackgroundImage" @deleteImage="deleteBackgroundImage"/>

        <v-label>
          <div class="mt-3 mb-1">Thank-you Video
            <tooltip top>
              Optionally, add a thank-you video to be displayed when fans make a purchase.
            </tooltip>
          </div>
        </v-label>
        <img v-if="thankYouVideo" :src="thankYouVideo.replace('mp4', 'jpg')" width="110">
        <select-image-button video :image="thankYouVideo" :boxOffice="boxOffice" @selectImage="selectThankYouVideo" @deleteImage="deleteThankYouVideo"/>
        </v-form>

        <div class="mt-4 mb-1">
          <v-label>Page content
            <tooltip right>
              Enter a description for your Event home page
            </tooltip>
          </v-label>
        </div>
        <rich-text-editor  v-model="editEvent.description"/>

        <div class="mt-4 mb-1">
          <v-label>Email Text
            <tooltip right>
              When customers complete a ticket order, they will get an email confirmation.  You can add additional instructions here.
            </tooltip>
          </v-label>
        </div>
        <rich-text-editor v-model="editEvent.emailText"/>

        <v-row class="mt-5">
          <v-col cols="auto">
            <v-btn color="success" @click="updateEvent">Save</v-btn>
            <v-btn color="white" @click="showEditToggle = false">Cancel</v-btn>
            <v-btn color="white" @click="duplicateEvent">Duplicate</v-btn>
            <tooltip top>If you are planning a similar event, it may be easiest to duplicate<br/>this one and then change the dates and other details.</tooltip>
          </v-col>
          <v-spacer/>
          <v-col cols="auto">
            <v-btn class="mr-0" color="error" @click="deleteEvent">Delete</v-btn>
          </v-col>
        </v-row>

      </div>

      <div v-else>
        <v-row>
          <v-col cols="3">Event Homepage</v-col>
          <v-col cols="9">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <a :href="makeURL()" target="_blank">{{makeURL()}}</a>
                <v-icon v-on="on" light class="ml-4" @click.stop.prevent="copyPath">mdi-content-copy</v-icon>
                <input type="hidden" id="pathToCopy" :value="makeURL()">
              </template>
              <slot>Click to copy URL</slot>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3">Name</v-col>
          <v-col cols="9">{{event.name}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Email<span v-if="$vuetify.breakpoint.smAndUp"> for correspndance</span></v-col>
          <v-col cols="9">{{event.email}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Location</v-col>
          <v-col cols="9">{{event.location}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Path</v-col>
          <v-col cols="9">{{event.path}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Ticket pin</v-col>
          <v-col cols="9">{{event.pin}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="3">URL when sold out</v-col>
          <v-col cols="9">{{event.soldoutUrl || '-'}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Sold Tickets</v-col>
          <v-col cols="9">{{event.soldTickets}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Remaining Tickets</v-col>
          <v-col cols="9">{{event.remainingTickets}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Allow Donations?</v-col>
          <v-col cols="9">{{event.allowDonations}}</v-col>
        </v-row>
        <v-row v-if="event.allowDonations">
          <v-col cols="3">Donation Prompt</v-col>
          <v-col cols="9">{{event.donationPrompt}}</v-col>
        </v-row>
        <v-row v-if="event.allowDonations">
          <v-col cols="3">Default Donation Amount</v-col>
          <v-col cols="9">{{utils.priceDisplay(event.defaultDonationAmount, boxOffice.currency)}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Hide dates on Box Office page?</v-col>
          <v-col cols="9">{{event.hideDatesOnBoxOfficePage}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="3">QR Codes?</v-col>
          <v-col cols="9">{{event.qrCodes}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Collect Location?</v-col>
          <v-col cols="9">{{event.collectLocation}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Collect Date of Birth?</v-col>
          <v-col cols="9">{{event.collectDateOfBirth}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Collect Phone Numbers?</v-col>
          <v-col cols="9">{{event.collectPhoneNumber}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Summary</v-col>
          <v-col cols="9">{{event.summary}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Priority</v-col>
          <v-col cols="9">{{event.priority}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Facebook Pixel</v-col>
          <v-col cols="9">{{event.facebookPixel}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="3">Colors</v-col>
          <v-col cols="9"><v-sheet class="text-center text-justify-center" height="100%" :color="event.backgroundColour" :style="'color:' + event.textColour"><pre class="pt-0">{{event.textColour}}</pre></v-sheet></v-col>
        </v-row>
        <v-row v-if="event.eventImage">
          <v-col cols="3">Event Image</v-col>
          <v-col cols="9">
            <img :src="getCloudinaryImage({url: event.eventImage, width: 110})">
          </v-col>
        </v-row>
        <v-row v-if="event.backgroundImage">
          <v-col class="pr-0" cols="3">Background Image</v-col>
          <v-col cols="9">
            <img :src="getCloudinaryImage({url: event.backgroundImage, width: 110})">
          </v-col>
        </v-row>
        <v-row v-if="event.thumbnail">
          <v-col cols="3">Thumbnail</v-col>
          <v-col cols="9">
            <img :src="getCloudinaryImage({url: event.thumbnail, width: 110})">
          </v-col>
        </v-row>
        <v-row v-if="event.description">
          <v-col cols="12"><b>Description</b><div v-html="event.description"/></v-col>
        </v-row>
        <v-row v-if="event.emailText">
          <v-col cols="12"><b>Email Text</b><div v-html="event.emailText"/></v-col>
        </v-row>
      </div>
    </base-material-card>
  </v-container>
  <event-times/>
  <ticket-types/>
  </div>
</template>
  
<script>
import Api from '@/services/Api.js'; 
import Utils from '@/services/Utils.js';
import EventUtils from '@/services/EventUtils.js';
import Store from '@/services/Store.js';
import { getCloudinaryImage } from '@/helpers/cloudinary'
import { getCurrencyPrefix } from '@/helpers/currency'
import { commonValidationRules } from "@/services/Utils"

  export default {
    name: 'Event',

    components: {
      BaseMaterialCard: () => import('@/components/base/MaterialCard'),
      Tooltip: () => import('./components/Tooltip'),
      TextField: () => import('./components/TextField'),
      ToggleButton: () => import('./components/ToggleButton'),
      SelectImageButton: () => import('./components/SelectImageButton'),
      SelectColours: () => import('./components/SelectColours'),
      RichTextEditor: () => import('./components/RichTextEditor2'),
      EventTimes: () => import('./EventTimes'),
      TicketTypes: () => import('./TicketTypes'),
    },  

    data () {
      return {
        urlValidRules: commonValidationRules.urlValidRules,
        getCurrencyPrefix,
        getCloudinaryImage,
        thumbnail: "",
        backgroundImage: "",
        thankYouVideo: "",
        eventImage: "",
        event: {},
        ticketTypes: [],
        eventTimes: [],
        editEvent: {},
        boxOffice: {},
        user: {},
        images: null,
        videos: null,
        showEditToggle: false,
      }
    },
    
    computed: {
      utils: function () {
        return Utils;
      },
      eventUtils: function () {
        return EventUtils;
      }
    },

    created() {
      this.event.id = this.$route.params.id;
      this.load();
    },

    methods: {

      donationRule(value) {
        return Utils.donationRule(this.boxOffice, value);
      },

      copyPath () {
          let pathToCopy = document.querySelector('#pathToCopy')
          pathToCopy.setAttribute('type', 'text')    // ?? hidden ????
          pathToCopy.select()

          try {
            document.execCommand('copy');
          } catch (err) {
            alert('Sorry, unable to copy');
          }

          /* unselect the range */
          pathToCopy.setAttribute('type', 'hidden')
          window.getSelection().removeAllRanges()
      },

      loadTicketTypes() {
        return "/Dashboard/TicketTypes/" + this.event.id;
      },

      loadEventTimes() {
        return "/Dashboard/EventTimes/" + this.event.id;
      },

      async load() {
        this.event = await Api.post(this, "Event", "readWithSales", this.event);
        this.boxOffice.id = this.event.boxOfficeId;
        this.boxOffice = await Api.post(this, "BoxOffice", "read", this.boxOffice);
        Store.store(this.boxOffice, this.event);
        this.ticketTypes = await Api.post(this, "TicketType", "list", this.event);
        this.eventTimes = await Api.post(this, "EventTime", "list", this.event);
        const images = await Api.post(this, "Image", "list", this.boxOffice);
        this.images = images.filter(f => !f.url.endsWith('mp4'))
        this.videos = images.filter(f => f.url.endsWith('mp4'))
      },

      async updateEvent() {
        if (! await Utils.validate(this.$refs.event) ) {
          return;
        }
        this.event = this.editEvent;
        if (this.event.allowDonations) {
          this.event.defaultDonationAmount = Utils.priceToNumber(this.editEvent.defaultDonationAmount);
        } else {
          this.event.defaultDonationAmount = 0;
        }
        this.event = await Api.post(this, "Event", "update", this.event);
        this.showEdit(false);
      },

      priceRule(value) {
        return Utils.priceRuleAndCheckStripe(this.boxOffice, value);
      },

      showEdit(show) {
        if (show) {
          this.editEvent = Utils.jsonCopy(this.event);
          this.editEvent.defaultDonationAmount = Utils.priceToString(this.event.defaultDonationAmount);
          this.thumbnail = this.editEvent.thumbnail;
          this.backgroundImage = this.editEvent.backgroundImage;
          this.thankYouVideo = this.editEvent.thankYouVideo;
          this.eventImage = this.editEvent.eventImage;
          if (!this.editEvent.donationPrompt) { 
            this.editEvent.donationPrompt = "Please donate in order to help fund this event";
          }
        }
        this.showEditToggle = show;
      },

      async publish(published) {
        this.event.published = published;
        await Api.post(this, "Event", "update", this.event);
        this.showEdit(false);
      },

      selectThumbnailImage(image) {
        this.thumbnail = image;
        this.editEvent.thumbnail = image;
      },

      selectBackgroundImage(image) {
        this.backgroundImage = image;
        this.editEvent.backgroundImage = image;
      },

      selectThankYouVideo (video) {
        this.thankYouVideo = video;
        this.editEvent.thankYouVideo = video;
      },

      selectEventImage(image) {
        this.eventImage = image;
        this.editEvent.eventImage = image;
      },

      deleteThumbnailImage() {
        this.thumbnail = '';
        this.editEvent.thumbnail = '';
      },

      deleteBackgroundImage() {
        this.backgroundImage = '';
        this.editEvent.backgroundImage = '';
      },

      deleteThankYouVideo () {
        this.thankYouVideo = '';
        this.editEvent.thankYouVideo = '';
      },

      deleteEventImage() {
        this.eventImage = '';
        this.editEvent.eventImage = '';
      },

      async deleteEvent() {
        await Api.post(this, "Event", "delete", this.event);
        this.$router.push("/Dashboard/Events/" + this.boxOffice.id);
      },

      async duplicateEvent() {
        let ticketTypes = await Api.post(this, "TicketType", "list", this.event);
        this.event.name = this.event.name + "-copy"
        this.event.path = null;
        this.event.published = false;
        this.event = await Api.post(this, "Event", "create", this.event);
        for (let ticketType of ticketTypes) {
          ticketType.eventId = this.event.id;
          await Api.post(this, "TicketType", "create", ticketType);
        }
        this.$router.push("/Dashboard/Events/" + this.boxOffice.id);
      },
      
      async selectColours(backgroundColour, textColour) {
        this.editEvent.textColour = textColour;
        this.editEvent.backgroundColour = backgroundColour;
      },

      makeURL() {
        return EventUtils.makeURL(this.boxOffice, this.event);
      },

      descriptionChanged(description) {
        this.editEvent.description = description;
      },

      emailTextChanged(description) {
        this.editEvent.emailText = description;
      },
    }
  }
</script>
